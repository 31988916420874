function emit(name, value) {
  this.$emit(name, value);
  console.log("name: ", name, "value: ", value);
}

function handleDateBasic(date) {
  date = new Date(date);
  return date.toDateString();
}

function handleDateShort(date) {
  date = this.handleDateBasic(date);
  date = date.split(" ");
  return `${date[0]} ${date[2]} ${date[1]}, ${date[3]}`;
}

function startAgain() {
  const setup = this.$store.state.setup;
  setup.progress = {
    value: 5,
    max: 100,
  };
  setup.route = {
    justArrived: true,
    collection: {
      active: false,
      step: {
        one: false,
        two: false,
        three: false,
      },
    },
    event: {
      active: false,
      activity: null,
      performance: null,
      step: {
        one: false,
        two: false,
        three: false,
      },
    },
  };
}

function eventStep(step) {
  const setup = this.$store.state.setup;
  setup.route.justArrived = false;
  setup.route.event.active = true;

  switch (step) {
    case "one":
      setup.progress.value = 40;
      setup.route.event.step = {
        one: true,
        two: false,
        three: false,
      };
      break;
    case "two":
      setup.progress.value = 60;
      setup.route.event.step = {
        one: false,
        two: true,
        three: false,
      };
      break;
    default:
      setup.progress.value = 100;
      setup.route.event.step = {
        one: false,
        two: false,
        three: true,
      };
  }
}

function collectionStep(step) {
  const setup = this.$store.state.setup;
  setup.route.justArrived = false;
  setup.route.collection.active = true;

  switch (step) {
    case "one":
      setup.progress.value = 40;
      setup.route.collection.step = {
        one: true,
        two: false,
        three: false,
      };
      break;
    case "two":
      setup.progress.value = 60;
      setup.route.collection.step = {
        one: false,
        two: true,
        three: false,
      };
      break;
    default:
      setup.progress.value = 100;
      setup.route.collection.step = {
        one: false,
        two: false,
        three: true,
      };
  }
}

function selectValue(value, name, index) {
  this.$emit(name, { value, index });
  console.log("name: ", name, "value: ", value);
}

export default {
  methods: {
    emit,
    handleDateBasic,
    handleDateShort,
    startAgain,
    collectionStep,
    eventStep,
    selectValue,
  },
};
