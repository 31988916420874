<template>
  <div class="component-step-two">
    <b-button variant="link-dark" @click="eventStep('one')">Back</b-button>

    <setup-performance
      v-if="$store.state.setup.route.event.activity === 'Performance'"
    />
    <custom-row
      class="select-performance-type"
      title="Performance Type"
      id="performance-type"
      required
    >
      <select-performance-type
        @SelectPerformanceType="SelectPerformanceType"
        id="performance-type"
      />
    </custom-row>

    <div class="next-step-button">
      <b-button
        :disabled="$store.state.setup.route.event.performance ? false : true"
        @click="eventStep('three')"
        >Next</b-button
      >
    </div>
  </div>
</template>

<script>
import Mixins from "../../../../mixins";

import { CustomRow } from "../../../Custom";
export default {
  name: "StepTwo",
  mixins: [Mixins],
  components: {
    CustomRow,
  },
  props: {
    activities: {
      type: String,
      required: false,
    },
  },
  methods: {
    SelectPerformanceType({ value }) {
      this.$store.state.setup.route.event.performance = value;
    },
  },
};
</script>
