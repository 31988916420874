<template>
  <event-list></event-list>
</template>

<script>
import {
  CalendarDisplay,
  CalendarSummary,
  CalendarCreate,
} from "../../components/Calendar";
import {
  EventList
} from "../../components/Event";
export default {
  name: "Calendar",
  components: {
    CalendarDisplay,
    CalendarSummary,
    CalendarCreate,
    EventList
  },
};
</script>
