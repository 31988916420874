import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// apollo graphql
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink, createApolloLink } from "apollo-link-http";
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from "apollo-cache-inmemory";

// bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// custom scss for bootstrap
import "./App.scss";

// install BootstrapVue and its icon components plugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// load msal and log the user in to their 365 account
import * as msal from '@azure/msal-browser';

const msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_AZURE_AD_APP_ID,
    authority: process.env.VUE_APP_AZURE_AD_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
    autoRefreshToken: true,
    requireAuthOnInitialize: true    
  }
});

// make msal instance available to components
Object.defineProperty(Vue.prototype, '$msal', { value: msalInstance })

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_BARBICAN_GRAPH_URL,
  credentials: 'include'
});

// https://www.apollographql.com/docs/react/v2/networking/network-layer
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage
  // the token is set in App.vue, but should be set before this function is called

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: `Bearer ${localStorage.getItem('systems.barbican.graph.token')}` || null,
    }
  });

  return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
  resolvers: {}
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

// install VueApollo for graphql
Vue.use(VueApollo);


// hotjar for feedback
import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: '3191747' // Hotjar Site ID
})


new Vue({
  router,
  store,
  apolloProvider,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");  
