<template>
  <div class="view-saved-searches">
    <dummy-tabs :tabs="tabs" />
    <div class="tab-pane">
      <b-card class="saved-searches">
        <b-card
          class="saved-search"
          v-for="(search, index) in searches"
          :key="index"
        >
          <b-row>
            <b-col sm="3">
              <label>Title</label>
            </b-col>
            <b-col sm="9">
              <div :id="search.title">{{ search.title }}</div>
            </b-col>
          </b-row>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import DummyTabs from "../../../components/Tabs";
export default {
  name: "SavedSearches",
  components: {
    DummyTabs,
  },
  data() {
    return {
      tabs: [
        {
          to: { name: "search" },
          title: "New",
        },
      ],
      searches: [
        {
          title: "First Snapshot",
        },
        {
          title: "Second Snapshot",
        },
      ],
    };
  },
};
</script>
