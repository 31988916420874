<template>
  <b-card class="calendar-create">
    <b-calendar v-if="!value" v-model="value" block />
    <calendar-display
      :day="handleDateShort(value)"
      @changeDay="value = null"
      v-else
      edit
    />
    <div class="close-tab__button">
      <b-button size="sm" variant="danger" @click="emit('closeTab', i)">
        Close tab
      </b-button>
    </div>
  </b-card>
</template>

<script>
import Mixins from "../../../mixins";
import CalendarDisplay from "../Display";
export default {
  name: "CalendarCreate",
  mixins: [Mixins],
  components: {
    CalendarDisplay,
  },
  props: {
    i: { type: Number },
  },
  data() {
    return {
      value: "",
      context: null,
      selectedDate: null,
      sominElse: null,
    };
  },
};
</script>
