<template>
  <b-form-select
    :options="options"
    :value="value"
    :name="name"
    @change="$emit('input', $event)"    
  />
</template>

<script>
import Mixins from "../../../mixins";
export default {
  name: "SelectCollectionStatus",
  props: {
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
  },
  mixins: [Mixins],
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "DRAFT", text: "Draft" },
        { value: "CONFIRMED", text: "Confirmed" },
      ],
    };
  },
};
</script>
