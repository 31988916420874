<template>
  <vue-typeahead-bootstrap v-if="!$apollo.queries.users.loading"
    placeholder="Start typing to search..."
    v-model="searchString"
    :inputName="name"
    :data="users"
    :serializer="item => serializer(item)"
    :disabledValues="(selected ? [selected.id] : [])"
    @hit="$emit('input', $event)"
  />
</template>


<script>
import Mixins from "../../../mixins";
import gql from "graphql-tag";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  name: "SelectRelatedUser",
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    value: {
      type: Object,
      default: null,
    },    
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
  },
  mixins: [Mixins],
  apollo: {
    users: {
      query: gql`
        query { 
          users {  
            id,
            first_name, 
            last_name
          } 
        }
      `,        
    }
  },
  methods: {
    serializer(item) {
      return `${item.first_name} ${item.last_name}`
    },
  },      
  data() {
    return {
      selected: null,
      searchString: null
    };
  },
};
</script>
