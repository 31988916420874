import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchQuery: "",
    searchRegex: "",
    filters: {
      selectedDate: new Date(),
      activity: [],
      status: [],
      areas: [],
      groups: [],
      booking: undefined,
      signage_suppress: undefined,
      signage_description_regex: undefined
      // booker: undefined,
      // manager: undefined
    },    
    user: {},
    graphReady: false
  },
  mutations: {
    filters(state, data) {
      state.filters = {...state.filters, ...data}
    },
    selectedDate(state, data) {
      // date may be a string representation of the date
      let d = data instanceof Date ? data : new Date(data);   
      state.filters.selectedDate = d;
    },
    updateUser(state, data) {
      state.user = {...state.user, ...data};
    },
    updateSearch(state, data) {
      state.searchQuery = data;
      state.searchRegex = `(?i)${data}.*`;
    },
    updateList(state, data) {
      state.list = data;
    },
    updateGraphReady(state, data) {
      state.graphReady = data
    }
  },
  getters: {
    filters (state, getters) {
      return {
        activity: state.filters.activity.length > 0 ? state.filters.activity : undefined,
        status: state.filters.status.length > 0 ? state.filters.status : undefined,
        areas: state.filters.areas.length > 0 ? state.filters.areas : undefined,        
        groups: state.filters.groups.length > 0 ? state.filters.groups : undefined,
        booking: state.filters.booking,
        signage_suppress: state.filters.signage_suppress,
        signage_description_regex: state.filters.signage_description_regex,
        name: state.searchRegex == '' ? undefined : state.searchRegex
      }
    }
  },  
  actions: {},
  modules: {},
});
