<template>
  <div class="component-just-arrived">
    <div class="introduction">
      <p>
        For every <strong>Event</strong> there must be a <strong>Collection</strong>.
      </p>
      <p>Select your <strong>Collection</strong> or create a new one below.</p>
    </div>
    <b-row class="choose-collection">
      <b-col>
        <select-collection-name name="collection" v-model="collection" />
      </b-col>

      <b-col>
        <b-button
          :disabled="collection.id ? false : true"
          @click="eventStep('one')"
          variant="primary"
        >
          Select
        </b-button>
      </b-col>
    </b-row>
    <p>or</p>
    <b-button variant="success" @click="collectionStep('one')">
      Create Collection
    </b-button>
  </div>
</template>

<script>
import Mixins from "../../../mixins";
import { SelectCollectionName } from "../../Select";
export default {
  name: "JustArrived",
  mixins: [Mixins],
  components: {
    SelectCollectionName,
  },
  computed: {
    collection: {
      get() {
        return this.$store.state.setup.collection
      },
      set(value) {
        this.$store.commit('updateSetupCollection', value)
      }
    }
  }
};
</script>
