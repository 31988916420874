<template>
  <div id="app">
    <navigation-main v-if="loggedIn"/>
    <b-container fluid class="mx-auto">
      <router-view v-if="loggedIn"></router-view>
      <logged-out v-if="!loggedIn"></logged-out>
    </b-container>
  </div>
</template>

<script>
import Breadcrumb from "./components/Breadcrumb";
import { NavigationMain } from "./components/Navigation";
import { LoggedOut } from "./components/Navigation";
import { User } from './queries/user.graphql';

export default {
  components: {
    NavigationMain,
    Breadcrumb,
    LoggedOut
  },
  mounted() {
    this.$msal.loginPopup({}).then(user => {
      this.$store.commit("updateUser", user)

      // exchange the Microsoft ID token for a Barbican Graph token
      fetch(`${process.env.VUE_APP_BARBICAN_GRAPH_URL}/exchange-token`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ token: user.idToken }) // body data type must match "Content-Type" header
      })
      .then(response => response.text())
      .then(graphToken => {
        console.log("exchanged Microsoft ID token for Barbican token");
        localStorage.setItem('systems.barbican.graph.token', graphToken);
        this.$store.commit("updateGraphReady", true)
      })
      .catch(error => {
        console.error("couldn't exchange Microsoft ID token for a Barbican token");
      })
    })
    .catch(err => console.log(err))
  },  
  computed: {
    username() {
      if (this.$store.state.user.account) return this.$store.state.user.account.username;
    },
    loggedIn() {
      return this.$store.state.graphReady
    }
  },
  apollo: {
    $skipAll() {
      return !this.$store.state.graphReady
    },
    users: {
      query: User,
      variables() {
        return {
          username: this.username
        }
      },
      skip() {
        // skip if username not set
        return !this.username;
      },
      result({ data }) {
        let user = data.users[0];

        if (user) {
          console.log("hello", user)
          this.$store.commit('updateUser', { 
            graph_user_name: user.name,
            graph_user_email_address: user.email_address,
            graph_user_id: user.id
          })
        }        
      }
    }
  },  
};
</script>

