<template>
    <b-container class="mt-3 mr-3 ml-3" fluid="xs">
        <b-card>
            <div v-if="$apollo.queries.areas.loading">Loading...</div>
            <b-container fluid class="mt-0 mr-3 ml-3 gantt" v-if="!$apollo.queries.areas.loading">
                <b-row class="gantt-row">
                    <b-col cols="3">&nbsp;</b-col>
                    <b-col>
                        <div class="gantt-horizontal-axis">
                            <div v-for="marker in timeMarkers" class="time-marker" :style="ganttPosition(marker)">{{ marker.getHours() }}</div>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-for="area in areas" class="gantt-row">
                    <b-col cols="3" class="gantt-row-heading filterable" @click="filters = { 'areas': [ area.id ] }">
                        {{ area.name }}                
                    </b-col>
                    <b-col class="gantt-bar-set">
                        <div class="gantt-bar">
                            <div v-for="event in area.events" class="gantt-event" :style="ganttPosition(event.start, event.end)" :title="event.booking.name">
                                {{ event.booking.name }}
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </b-container>
  </template>
  
  <script>
    import { AreasEventsFiltered } from '../../../queries/area.graphql';
  
    export default {
        data() {
            return {
                division: "hours"
            }
        },
    
        computed: {
            filters: {
                get() {
                    return this.$store.state.filters;
                },
                set(f) {
                    // components can unthinkingly set (overwrite) filters if needed
                    // and the commit function will merge values, so it's just an update to the set property/ies
                    this.$store.commit('filters', f);
                }
            },            

            dateRange() {      
                let selected = this.$store.state.filters.selectedDate;

                // start of day at midnight
                let start = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), 0, 0, 0);
                let end = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), 23, 59, 59);

                let startTime = start.getTime();
                let endTime = end.getTime(); 

                return {
                    start: start,
                    end: end,
                    startTime: startTime,
                    endTime: endTime,
                    duration: endTime - startTime,
                }
            },

            timeMarkers() {
                let division = 60 * 60 * 1000; // milliseconds in an hour
                
                let firstHour = Math.ceil(this.dateRange.startTime / division) * division;
                let lastHour = Math.floor(this.dateRange.endTime / division) * division;

                let markers = []

                console.log(firstHour)
                console.log(lastHour)
                for (let t = firstHour; t <= lastHour; t += division) {
                    console.log(t)
                    markers.push(new Date(t))
                }

                return markers;
            }
        },

        methods: {
            ganttPosition(start, end) {
                let startTime = (new Date(start)).getTime();
                let left = Math.max(0, (startTime - this.dateRange.startTime) / this.dateRange.duration);              

                if (end) {
                    let endTime = (new Date(end)).getTime();
                    let duration = endTime - startTime;
                    let width = Math.min(duration / this.dateRange.duration, this.dateRange.endTime - startTime);

                    return {
                        left: `${left * 100}%`,
                        width: `${width * 100}%`,
                    }            
                } else {
                    return {
                        left: `${left * 100}%`,
                    }
                }
            }
        },
    
        apollo: {
            $skipAll() {
                return !(this.$store.state.user && this.$store.state.user.expiresOn > Date.now() && localStorage.getItem('systems.barbican.graph.token'));
            },
            areas: {
                query: AreasEventsFiltered,
                variables() {
                    return {
                    ...this.$store.getters.filters,
                    ...this.dateRange // we implement date range at a component level rather than in the store, so that components can set their own range
                    };
                }
            },
        },
        }
  </script>
  
  <style lang="scss">
    @import "~bootstrap";
    @import "~bootstrap-vue";

    .gantt-row {
        width: 100%;    
    }

    .gantt-row-heading {
        background-color: #F0F0F0;
        padding: 0.5rem;
    }

    .gantt-bar {
        background-color: #F0F0F0;
        height: 100%;
        overflow: hidden;    
        position: relative;
    }

    .gantt-horizontal-axis {
        height: 100%;
        overflow: hidden;    
        position: relative;
    }    

    .gantt-event {
        top: 0;
        bottom: 0;    
        padding: 0.5rem;
        white-space: nowrap;      
        box-sizing: border-box;
        background: linear-gradient(to right, #DDDDDD, 90%, #E5E5E5);
        display: inline;
        position: absolute;
        overflow: hidden;
    }

    .time-marker {
        position: absolute;
        padding: 0 0.5rem 0 0.5rem;
        top: 0;
        bottom: 0;
        border-left: 1px solid #E5E5E5;
    }

    .filterable:hover {
        color: cornflowerblue;
        cursor: pointer;
    }    
  </style>