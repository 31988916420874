<template>
  <vue-typeahead-bootstrap
    v-model="searchString"
    :inputName="name"
    :data="suggestions"
    :serializer="item => serializer(item)"
    :disabledValues="(selected ? [selected.id] : [])"
    @hit="$emit('input', $event)"
    @input="lookup"
  />
</template>


<script>
import Mixins from "../../../mixins";
import gql from "graphql-tag";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  name: "SelectRelatedPerson",
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    value: {
      type: Object,
      default: null,
    },    
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
  },
  mixins: [Mixins],
  methods: {
    lookup() {
      if (this.searchString.length < 3) {
        this.suggestions = [];
        return false;
      };

      this.$apollo.query({
        query: gql`
          query { 
            personSearch ( searchString: ${JSON.stringify(this.searchString)} ) { 
              first_name,
              last_name,
              name,
              id
            } 
          }
        `,
      })
      .then((result) => {
        this.suggestions = result.data.personSearch;
        return true;
      })
    },
    serializer(item) {
      return `${item.first_name} ${item.last_name}`
    },
  },      
  data() {
    return {
      suggestions: [],
      selected: null,
      searchString: null
    };
  },
};
</script>
