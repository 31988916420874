<template>
  <div v-if="this.$store.state.filters.booking">
    <label>Booking</label>
    <div v-for="booking in bookings" v-bind:key="booking.id">{{ booking.name }}</div>
    <b-button @click="filters = { booking: undefined }" class="row-button">clear</b-button>
  </div>
</template>

<script>

import { CustomCard } from "../../../components/Custom";
import { Booking } from "../../../queries/collection.graphql"

export default {
  name: "CollectionSelected",
  components: {
    CustomCard
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.filters;
      },
      set(f) {
        // components can unthinkingly set (overwrite) filters if needed
        // and the commit function will merge values, so it's just an update to the set property/ies
        this.$store.commit('filters', f);
      }
    },    
  },

  apollo: {
    bookings: {
      query: Booking,
      variables() {
        return {
          id: this.$store.state.filters.booking
        }
      },
      skip() {
        return this.$store.state.filters.booking == undefined
      }
    },
  }
};

</script>

<style lang="scss">
  .selected-collection {
    width: 270px;
  }

  .row-button {
    margin-top: 0.5rem;
  }
</style>
