<template>
  <b-container class="component-filter mt-3">
    <custom-card title="Filter">

    <!--
    <div class="filter-row">
      <div>Activity</div>
      <select-event-activity 
        v-model="filters.activity"
        name="activity"
        multiple 
      />
      <b-button :disabled="!filters.activity.length > 0" @click="filters = { activity: [] }">clear</b-button>
    </div>
    -->

    <div class="filter-row">
      <label for="groups">Group</label>
      <select-related-group
        v-model="filters.groups"
        name="groups"
        multiple
      />
      <b-button :disabled="!(filters.groups.length > 0)" @click="filters = { groups: [] }">clear</b-button>
    </div>

    <div class="filter-row">
      <label for="areas">Area</label>
      <select-related-area
        v-model="filters.areas"
        name="areas"
        multiple
      />
      <b-button :disabled="!(filters.areas.length > 0)" @click="filters = { areas: [] }">clear</b-button>
    </div>

    <div class="filter-row">
      <collection-selected></collection-selected>
    </div>

    <div class="filter-row" v-if="filters.status.length > 0">
      <label for="status">Status</label>
      <div>status is {{ filters.status.join(", ") }}</div>
      <!-- <select-event-status 
        v-model="filters.status"
        name="status" 
        multiple 
      /> -->
      <b-button :disabled="!filters.status.length > 0" @click="filters = { status: [] }">clear</b-button>
    </div>    

    <div class="filter-row" v-if="$store.state.searchQuery">
      <label>Search</label>
      <div>booking name contains <i>{{ $store.state.searchQuery }}</i></div>
      <b-button :disabled="!$store.state.searchQuery" @click="$store.commit('updateSearch', '')">clear</b-button>
    </div>

    <div class="filter-row">
      <b-checkbox
        v-model="onlySignage"
        id="checkbox-1"
        name="only-signage"
        checked
        switch
      >only events visible on signage</b-checkbox>
    </div>

    <!--
    <div class="filter-row">
      <div>Booker</div>
      <select-related-user
        name="booker"
        v-model="selected.booker" 
      />  
      <b-button variant="outline-secondary" @click="selected.booker=undefined">clear</b-button>
    </div>

    <div class="filter-row">
      <div>Manager</div>
      <select-related-user 
        name="manager"
        v-model="selected.manager" 
      />  
      <b-button variant="outline-secondary" @click="selected.manager=undefined">clear</b-button>
    </div>
    -->      
    </custom-card>
  </b-container>
</template>

<script>
import {
  SelectBookingStatus,
  SelectRelatedUser,
  SelectRelatedGroup,
  SelectRelatedArea,
  SelectEventActivity,
  SelectEventStatus,
} from "../../Select";

import { CustomCard } from "../../../components/Custom";
import { CollectionSelected } from "../../Collection";

export default {
  name: "EventFilter",
  components: {
    SelectBookingStatus,
    SelectRelatedUser,
    SelectRelatedGroup,
    SelectRelatedArea,
    SelectEventActivity,
    SelectEventStatus,  
    CustomCard,
    CollectionSelected
  },

  mounted() {
    this.onlySignage = true
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.filters;
      },
      set(f) {
        // components can unthinkingly set (overwrite) filters if needed
        // and the commit function will merge values, so it's just an update to the set property/ies
        this.$store.commit('filters', f);
      }
    },
    onlySignage: {
      // getter
      get() {
        return (this.filters.signage_suppress == false || this.filters.signage_suppress == undefined) && this.filters.signage_description_regex != undefined
      },
      // setter
      set(value) {
        if (value === true) {
          this.filters = {
            signage_suppress: false,
            signage_description_regex: '.+'  // 1 or more characters in description
          }          
        } else {
          this.filters = {
            signage_suppress: undefined,
            signage_description_regex: undefined
          }
        }
      }
    }    
  },

  data() {
    return {
      selected: {
        activity: [],
        status: [],
        groups: [],
        areas: [],
        booking: undefined,
        manager: undefined,
        booker: undefined
      }
    };
  },

  methods: {
    runFilter() {
      this.$store.commit('filters', this.selected);
    }
  },
};

</script>

<style src="./index.scss" lang="scss"></style>