<template>
  <b-card no-body class="calendar-display">
    <div class="show-hours">
      <hours-row time="11:00" />
      <hours-row time="12:00" />
      <hours-row time="13:00" />
      <hours-row time="14:00" />
      <hours-row />
    </div>
  </b-card>
</template>

<script>
import Mixins from "../../../mixins";
import HoursRow from "./HoursRow.vue";
export default {
  name: "CalendarDisplay",
  mixins: [Mixins],
  props: {
    day: {
      type: String,
      default: "Thu 19 Mar 2021",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HoursRow,
  },
};
</script>
