<template>
    <div>
        <div>

            <div>
              <b-spinner type="spinner" label="Loading..."></b-spinner>
            </div>

            <b-jumbotron>
              <h1>Loading...</h1>
              <h2>Use your Barbican account to log in. If you don't see a login window, please allow popups for this site in your browser.</h2>
            </b-jumbotron>
            <!-- 
            <div class="component-navigation-main"><logo></logo></div>
              <b-jumbotron header="Barbican Event" lead="This is an experimental event data app for the Barbican.">
                <p>You need to log in using your Barbican Microsoft account. For that to work, you might need to enable pop-ups in your browser for this site.</p>
                <p>This app may change with no notice. Please be ready for it not to work, but please do let us know if it doesn't. Help:
                <p><b-button variant="primary" href="mailto:systems@barbican.org.uk">Help on Teams</b-button></p>
                <p><b-button variant="primary" href="https://teams.microsoft.com/l/team/19%3aa8e2d777d10f47d8846535a6568a6050%40thread.skype/conversations?groupId=37c18d2d-4071-4bf5-b155-712d35d16656&tenantId=e744d46f-c876-4123-b939-a5e2cb4bad7d">Help by email</b-button></p>
                </p>
                <p>Made by the Barbican.</p>
            </b-jumbotron> -->
            </div>
    </div>
</template>

<script>
    import Logo from "../../SvgIcon/Logo.vue";

    export default {
      name: "LoggedOut",
      components: {
        Logo
      }
    };
</script>
