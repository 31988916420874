<template>
  <b-card no-body :class="prepClass(title)">
    <b-card-header>
      {{ title }}
    </b-card-header>
    <b-card-body>
      <slot></slot>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    title: {
      type: String,
      required: true,
      default: "Notifications",
    },
  },
  methods: {
    prepClass(value) {
      return value
        .toLowerCase()
        .split(" ")
        .join("-");
    },
  },
};
</script>
