<template>
  <b-container class="mt-3 mr-3 ml-3" fluid="xs">
    <b-card>
      <div v-if="$apollo.queries.events.loading">Loading...</div>
      <b-table v-else sticky-header="74vh" :items="events" :fields="fields">
        <template #cell(booking)="row">
          <div
            @click="filters = { 'booking': row.item.booking.id }"
            class="filterable"
            >{{ row.item.booking.name }}
            <div v-if="row.item.signage_description" class="signage-description">{{ row.item.signage_description }}</div>
          </div>
        </template>      

        <template #cell(area)="row">
          <div v-if="row.item.area" @click="filters = { 'areas': [ row.item.area.id ] }" class="filterable">{{ row.item.area.name }}</div>
        </template>

        <template #cell(activity)="row">
          <div @click="filters = { 'activity': [ row.item.activity ] }" class="filterable">{{ row.value }}</div>
        </template>       

        <template #cell(status)="row">
          <div @click="filters = { 'status': [ row.item.status ] }" class="filterable">{{ row.value }}</div>
        </template>            
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
  import { EventDetailsFiltered } from '../../../queries/event.graphql';

  export default {
    data() {
      return {
        fields: [
          { 
            key: 'start', 
            label: 'Start', 
            formatter: (value, key, item) => {
              let d = new Date(value);
              return (`${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`);
            },
            sortable: true, 
            sortDirection: 'asc' 
          },
          { 
            key: 'end', 
            label: 'End',
            formatter: (value, key, item) => {
              let d = new Date(value);
              return (`${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`);
            },            
            sortable: true 
          },
          {
            key: 'booking',
            label: 'Booking',
            sortable: true,
            sortByFormatted: true,
            tdClass: (value, key, item) => {

            }
          },
          { 
            key: 'area', 
            label: 'Area',
            sortable: true
          },
          // {
          //   key: 'activity', 
          //   label: 'Activity'
          // },
          { 
            key: 'status',
            label: 'Status',
            tdClass: (value, key, item) => {
              return `status_${value.toLowerCase()}`;
            }
          }
        ],
      }
    },

    computed: {
      selectedDate: {
        get() {
          return this.$store.state.filters.selectedDate;
        },
        set(d) {
          this.$store.commit('selectedDate', d);
        }
      },

      filters: {
        get() {
          return this.$store.state.filters;
        },
        set(f) {
          // components can unthinkingly set (overwrite) filters if needed
          // and the commit function will merge values, so it's just an update to the set property/ies
          this.$store.commit('filters', f);
        }
      },

      dateRange() {      
        // start of day at midnight
        let start = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 0, 0, 0);
        let end = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 23, 59, 59);

        return {
          start: start,
          end: end
        }
      }
    },

    apollo: {
      $skipAll() {
        return !(this.$store.state.user && this.$store.state.user.expiresOn > Date.now() && localStorage.getItem('systems.barbican.graph.token'));
      },
      events: {
        query: EventDetailsFiltered,
        variables() {
          return {
            ...this.$store.getters.filters,
            ...this.dateRange // we implement date range at a component level rather than in the store, so that components can set their own range
          };
        }
      },
    },
  }
</script>

<style lang="scss">
  @import "~bootstrap";
  @import "~bootstrap-vue";

  .status_guaranteed {
    border-left-width: 5px;
    border-left-color: deeppink;
    border-left-style: solid;
  }

  .status_confirmed {
    border-left-width: 5px;
    border-left-color: greenyellow;
    border-left-style: solid;
  }

  .status_scheduled {
    border-left-width: 5px;
    border-left-color: cornflowerblue;
    border-left-style: solid;
  }  

  .filterable:hover {
    color: cornflowerblue;
    cursor: pointer;
  }

  .signage-description {
    border-color: $gray-100;
    background-color: $gray-100;
    border-width: 1px;
    border-style: solid;
    padding: 0.25rem;
    font-size: 0.75rem;
  }

  .b-table {
    max-width: 100%;
  }
</style>