<template>
  <b-card>
    <b-form @change="save" v-for="event in events" v-bind:key="event.id">
      <div v-for="booking in event.booking" v-bind:key="booking.id">{{ booking.name }}</div>

      <b-form-group id="input-event-activity" label="Activity" label-for="activity">
        <select-event-activity name="activity" v-model="event.activity" required></select-event-activity>
      </b-form-group>

      <b-form-group id="input-event-status" label="Status" label-for="status">
        <select-event-status name="status" v-model="event.status" required></select-event-status>
      </b-form-group>

      <b-form-group id="input-event-area" label="Area" label-for="area">
        <select-related-area name="area" v-model="event.area"></select-related-area>
      </b-form-group>
      
      <b-form-group id="input-event-booker" label="Booker" label-for="booker">
        <select-related-user name="booker" v-model="event.booker"></select-related-user>
      </b-form-group>

    </b-form>
  </b-card>
</template>

<script>
import { Event, EventUpdate } from "../../../queries/event.graphql";
import { SelectEventActivity, SelectEventStatus, SelectRelatedArea, SelectRelatedUser } from "../../Select";

export default {
  name: "EventForm",
  props: ['id'],
  components: {
    SelectEventActivity,
    SelectEventStatus,
    SelectRelatedArea,
    SelectRelatedUser
  },
  methods: {    
    save(e) {
      this.$apollo.mutate({
        mutation: EventUpdate,
        variables: {
          id: this.id,
          [e.target.name]: e.target.value,
        }
      })
    }
  },
  apollo: {
    events: {
      query: Event,
      variables() {
        return {
          // id: this.$store.state.setup.event.id
          id: this.id
        }
      }
    }
  },
  data() {
    return {}
  }
}

</script>
