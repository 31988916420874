<template>
  <b-container fluid>
    <b-row no-gutters>
      <b-col sm="3">
        <calendar-picker></calendar-picker>
        <event-filter></event-filter>
      </b-col>
      <b-col>
        <router-view />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { NavigationLink } from "../../components/Navigation";
import { CalendarPicker } from "../../components/Calendar";
import { EventFilter } from "../../components/Event";

export default {
  name: "Dashboard",
  components: {
    NavigationLink,
    CalendarPicker,
    EventFilter
  },
};
</script>
