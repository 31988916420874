<template>
  <b-form class="search-form" :class="variant" @submit.prevent>
    <b-form-input
      placeholder="Search events"
      class="navigation-search"
      v-model="text"
    />
    <b-button type="submit" class="search-submit btn" @click="searchSubmit">
      <search-svg />
    </b-button>
  </b-form>
</template>

<script>
import { SearchSvg } from "../SvgIcon";
export default {
  name: "SearchForm",
  props: {
    variant: {
      type: String,
      default: "dark",
    },
  },
  components: {
    SearchSvg,
  },
  data() {
    return {
      text: this.$store.state.searchQuery,
    };
  },
  
  methods: {
    searchSubmit() {
      this.$store.commit("updateSearch", this.text);

      // if (this.$route.name !== "search") {
      //   this.$router.push({ name: "search" });
      // }
    },
  },

  // this is done as a watcher because we don't want this to be directly reactive
  watch: {
    "$store.state.searchQuery": function() {
      this.text = this.$store.state.searchQuery;
    },
  },
};
</script>
