<template>
  <div class="component-step-one">
    <b-button variant="link-dark" @click="startAgain">Back</b-button>

    <event-form v-if="$store.state.setup.event.id && $store.state.setup.collection.id"></event-form>

    <div class="next-step-button">
      <b-button
        :disabled="$store.state.setup.route.event.activity ? false : true"
        @click="eventStep('two')"
        >Next</b-button
      >
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import EventForm from "../../Form";
import Mixins from "../../../../mixins";

export default {
  name: "StepOne",
  mixins: [Mixins],
  components: {
    EventForm
  },
  mounted() {
    this.createRecord();
  },
  data() {
    return {
      id: null,
    }
  },
  methods: {
    createRecord() {
      this.$apollo.mutate({        
        mutation: gql`mutation { CreateEvent { id } }`  // create event object with no id, and return id created by db    
      })
      .then(result => {
        this.$store.state.setup.event.id = result.data.CreateEvent.id;

        // attach current user as booker
        let query = `mutation { 
          AddEventBooker (
            from: { id: ${JSON.stringify(result.data.CreateEvent.id)} }, 
            to: { id: ${ JSON.stringify(this.$store.state.user.id) } }
          ) { to { id } } 

          AddEventCollection (
            from: { id: ${JSON.stringify(result.data.CreateEvent.id)} }, 
            to: { id: ${ JSON.stringify(this.$store.state.setup.collection.id) } }
          ) { to { id } } 
        }`;
        console.log(query)
        this.$apollo.mutate({        
          mutation: gql`${query}`  
        })
      })
      .catch(error => {
        console.error(error);
      })
    },
  }
};
</script>
