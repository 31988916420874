<template>
  <div>
    <b-form-select
      :options="statuses.map(status => ({ value: status.id, text: status.name }))"
      :value="value"
      :name="name"
      :multiple="multiple"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "SelectEventStatus",
  props: ['value', 'name', 'multiple'],
  data() {
    return {
      statuses: [
        {
          "id": "CONFIRMED",
          "name": "Confirmed"
        },
        {
          "id": "PROVISIONAL",
          "name": "Provisional"
        },
        {
          "id": "SCHEDULED",
          "name": "Scheduled Event"
        },
        {
          "id": "SECOND_RESERVE",
          "name": "Second Reserve"
        },
        {
          "id": "THIRD_RESERVE",
          "name": "Third Reserve"
        },
        {
          "id": "CANCELLED",
          "name": "Cancelled"
        },
        {
          "id": "RELEASED",
          "name": "Released"
        },
        {
          "id": "SITE_INSPECTION",
          "name": "Site Inspection"
        },
        {
          "id": "GUARANTEED",
          "name": "Guaranteed"
        },
        {
          "id": "TENTATIVE",
          "name": "Tentative/Placeholder"
        },
        {
          "id": "SUBEVENT",
          "name": "SubEvent"
        },
        {
          "id": "RESOURCES",
          "name": "Resources"
        }
      ]
    }
  }  
};
</script>
