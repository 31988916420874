import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "../views/Dashboard";
import Calendar from "../views/Calendar";
import Gantt from "../components/Event/Gantt";

import EventForm from "../components/Event/Form";

// import Notifications from "../views/Notifications";
// import NewNotifications from "../views/Notifications/New";
// import SavedNotifications from "../views/Notifications/Saved";

import Search from "../views/Search";
import NewSearch from "../views/Search/New";
import SavedSearches from "../views/Search/Saved";

// import Snapshots from "../views/Snapshots";
// import CreateSnapshot from "../views/Snapshots/Create";
// import SavedSnapshots from "../views/Snapshots/Saved";

// import MyEvents from "../views/MyEvents";
// import CreateEvent from "../views/MyEvents/Create";
// import SavedEvents from "../views/MyEvents/Saved";

// import List from "../views/List.vue";
// import Node from "../views/Node.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Dashboard,
    meta: {
      title: "Home",
    },
    children: [
      {
        path: "/",
        name: "calendar",
        component: Calendar,
        meta: {
          title: "Calendar",
        },
      },
      {
        path: "gantt",
        name: "gantt",
        component: Gantt,
        meta: {
          title: "Gantt",
        },
      },      
      // {
      //   path: "notifications",
      //   component: Notifications,
      //   meta: {
      //     title: "Notifications",
      //   },
      //   children: [
      //     {
      //       path: "",
      //       name: "notifications",
      //       component: NewNotifications,
      //       meta: {
      //         title: "New Notifications",
      //       },
      //     },
      //     {
      //       path: "saved",
      //       name: "saved-notifications",
      //       component: SavedNotifications,
      //       meta: {
      //         title: "Saved Notifications",
      //       },
      //     },
      //   ],
      // },

      // {
      //   path: "snapshots",
      //   component: Snapshots,
      //   meta: {
      //     title: "Snapshots",
      //   },
      //   children: [
      //     {
      //       path: "",
      //       name: "snapshots",
      //       component: SavedSnapshots,
      //       meta: {
      //         title: "Saved Snapshots",
      //       },
      //     },
      //     {
      //       path: "create",
      //       name: "create-snapshot",
      //       component: CreateSnapshot,
      //       meta: {
      //         title: "Create Snapshot",
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "my-events",
      //   component: MyEvents,
      //   meta: {
      //     title: "My Events",
      //   },
      //   children: [
      //     {
      //       path: "",
      //       name: "my-events",
      //       component: SavedEvents,
      //       meta: {
      //         title: "Saved Events",
      //       },
      //     },
      //     {
      //       path: "create",
      //       name: "create-event",
      //       component: CreateEvent,
      //       meta: {
      //         title: "Create Event",
      //       },
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/search",
    component: Search,
    meta: {
      title: "Search",
    },
    children: [
      {
        path: "",
        name: "search",
        component: NewSearch,
        meta: {
          title: "New Search",
        },
      },
      {
        path: "saved",
        name: "saved-searches",
        component: SavedSearches,
        meta: {
          title: "Saved Searches",
        },
      },
    ],
  },  
  {
    path: "/event/:id",
    name: "Event",
    component: EventForm,
    props: (route) => ({
      id: route.params.id
    })
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
