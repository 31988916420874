<template>
  <b-form-select
    :options="groups ? groups.map(group => ({ value: group.id, text: (group.name) })) : []"
    :value="value != null ? value : undefined"
    :name="name"
    :multiple="multiple"
    @change="$emit('input', $event)"
  />
</template>

<script>
import { GroupsFiltered } from '@/queries/area.graphql';

export default {
  name: "SelectRelatedGroup",
  props: ['value', 'name', 'multiple'],
  apollo: {
    $skipAll() {
      return !(this.$store.state.user && this.$store.state.user.expiresOn > Date.now())
    },        
    groups: {
      query: GroupsFiltered,
      variables() {
        return {
          ...this.$store.getters.filters,
        };
      }      
    }
  },  
};
</script>
