<template>
  <ul class="nav nav-tabs">
    <li v-for="(tab, index) in tabs" class="nav-item" :key="index">
      <a v-if="tab.active" class="nav-link active">
        {{ tab.title }}
      </a>
      <router-link v-else class="nav-link" :to="tab.to">
        {{ tab.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "DummyTabs",
  props: {
    tabs: {
      type: Array,
    },
  },
};
</script>
