<template>
  <div class="component-calendar-summary">
    <custom-card title="Summary">
      <div class="summary-wrap">
        Summary content
      </div>
    </custom-card>
  </div>
</template>

<script>
import { CustomCard } from "../../../components/Custom";
export default { name: "CalendarSummary", components: { CustomCard } };
</script>
