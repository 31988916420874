<template>
  <div class="hours-row">
    <div class="time">
      <span>{{ time }}</span>
    </div>
    <div class="events"></div>
  </div>
</template>

<script>
export default { name: "HoursRow", props: { time: { type: String } } };
</script>
