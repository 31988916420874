<template>
  <div class="view-search">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Search",
};
</script>
