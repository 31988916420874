<template>
  <div class="component-navigation-main">
    <b-nav>
      <b-nav-item class="logo"><router-link to="/"><logo /></router-link></b-nav-item>
      <div class="nav-group">
        <b-nav-item class="search">
          <search-form variant="light" />
        </b-nav-item>
        <b-nav-item class="dropdown">               
          <b-dropdown v-if="$store.state.user.account" variant="link" id="dropdown-1" right :text="$store.state.user.account.name"></b-dropdown>
        </b-nav-item>
      </div>
    </b-nav>
  </div>
</template>

<script>
import SearchForm from "../../Search";
import Logo from "../../SvgIcon/Logo.vue";
export default {
  name: "NavigationMain",
  components: {
    SearchForm,
    Logo,
  },
};
</script>
