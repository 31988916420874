<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <path
      d="M7.5,7.4C6.6,8.3,6,9.7,6,11.1s0.6,2.7,1.5,3.7c0.9,0.9,2.3,1.5,3.7,1.5 s2.7-0.6,3.7-1.5c0.9-0.9,1.5-2.3,1.5-3.7s-0.6-2.7-1.5-3.7c-1-1.1-2.3-1.5-3.7-1.5S8.5,6.4,7.5,7.4z M19.5,21.4l-4-4 c-1.2,0.9-2.7,1.3-4.2,1.3c-2.1,0-4-0.8-5.4-2.2c-1.4-1.4-2.2-3.3-2.2-5.3s0.8-4,2.2-5.4s3.4-2.3,5.4-2.3s4,0.8,5.4,2.2 S19,9,19,11.1c0,1.5-0.4,3-1.3,4.3l4,4L19.5,21.4z"
      fill-rule="evenodd"
      clip-rule="evenodd"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "SearchSvg",
};
</script>
