<template>
  <b-list-group-item class="navigation-link__item"
    ><b-link
      :to="{ name: to }"
      class="navigation-link__anchor"
      :exact="exact"
      >{{ title }}</b-link
    ></b-list-group-item
  >
</template>

<script>
export default {
  name: "NavigationLink",
  props: {
    title: {
      type: String,
    },
    to: {
      type: String,
    },
    exact: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
