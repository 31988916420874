<template>
  <div class="component-breadcrumb">
    <div class="breadcrumb-wrap">
      <b-breadcrumb>
        <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
        <b-breadcrumb-item class="location" :href="this.$route.path">{{
          prepName(this.$route.name)
        }}</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  methods: {
    prepName(value) {
      return value.split("-").join(" ");
    },
  },
};
</script>
