<template>
  <label :for="id"
    >{{ title }}<span v-if="required" class="required">(required)</span></label
  >
</template>

<script>
export default {
  name: "CustomLabel",
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
};
</script>
