<template>
  <b-form-select
    :options="areas ? areas.map(area => ({ value: area.id, text: (area.name) })) : []"
    :value="value != null ? value : undefined"
    :name="name"
    :multiple="multiple"
    @change="$emit('input', $event)"
  />
</template>

<script>
import { Areas, AreasFiltered } from '../../../queries/area.graphql';

export default {
  name: "SelectRelatedArea",
  props: ['value', 'name', 'multiple'],
  apollo: {
    $skipAll() {
      return !(this.$store.state.user && this.$store.state.user.expiresOn > Date.now())
    },        
    areas: {
      query: AreasFiltered,
      variables() {
        return {
          ...this.$store.getters.filters,
        };
      }      
    }
  },    
};
</script>
