<template>
  <b-form-select
    @change="selectValue($event, 'SelectCollectionTemplate', index)"
    :options="options"
    :value="value"
    :id="id"
  />
</template>

<script>
import Mixins from "../../../mixins";
export default {
  name: "SelectCollectionTemplate",
  props: {
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: false,
    },
  },
  mixins: [Mixins],
  data() {
    return {
      options: [
        { value: null, text: "Please select an option" },
        { value: "Wedding Template", text: "Wedding Template" },
        { value: "Conference Template", text: "Conference Template" },
        { value: "Concert Template", text: "Concert Template" },
      ],
    };
  },
};
</script>
