<template>
  <b-row>
    <b-col sm="4">
      <custom-label :title="field.title" :required="field.required" />
    </b-col>
    <b-col sm="8">
      <b-form-input v-if="!(customInputTypes.includes(field.inputType))" :name="name" :type="field.inputType" v-model="field.value"></b-form-input>
      <component v-else :is="field.inputType" :name="name" v-model="field.value"></component>
    </b-col>
  </b-row>
</template>

<script>
import {
  SelectEventActivity,
  SelectEventStatus,
  SelectRelatedPerson,
  SelectRelatedUser,
  SelectRelatedArea,
  SelectCollectionStatus,
  SelectCollectionTemplate,
} from "../Select";

import { CustomLabel } from "./index";

export default {
  name: "CustomRow",
  props: ['field', 'name'],
  components: {
    SelectEventActivity,
    SelectEventStatus,
    SelectRelatedPerson,
    SelectRelatedUser,
    SelectRelatedArea,
    SelectCollectionStatus,
    SelectCollectionTemplate,
    CustomLabel
  },  
  data() {
    return {
      customInputTypes: [
        "select-event-activity",
        "select-event-status",
        "select-related-person",
        "select-related-user",
        "select-related-area",
        "select-collection-status",
        "select-collection-template"
      ]
    }
  } 
};
</script>

