<template>
  <div>
    <b-form-select
      :options="activities.map(activity => ({ value: activity.id, text: activity.name }))"
      :value="value"
      :name="name"
      :multiple="multiple"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "SelectEventActivity",
  props: ['value', 'name', 'multiple'],
  data() {
    return {
      activities: [
        {
          "id": "EVENT_IN_PROGRESS",
          "name": "Event in progress"
        },
        {
          "id": "VENUE_HIRE",
          "name": "Venue hire / hold"
        },
        {
          "id": "CATERING_SERVICE",
          "name": "Catering service"
        },
        {
          "id": "ACCESS",
          "name": "Access"
        },
        {
          "id": "REHEARSAL",
          "name": "Rehearsal"
        },
        {
          "id": "GET_OUT",
          "name": "Get-out / event breakdown"
        },
        {
          "id": "PERFORMANCE",
          "name": "Performance"
        },
        {
          "id": "BRIEFING",
          "name": "Briefing / doors open"
        },
        {
          "id": "GET_IN",
          "name": "Get-in / event set-up"
        },
        {
          "id": "AUDITION",
          "name": "Audition"
        },
        {
          "id": "ORGANISERS_ARRIVE",
          "name": "Organisers arrive"
        },
        {
          "id": "OVERNIGHT_HOLD",
          "name": "Overnight hold"
        },
        {
          "id": "OVERNIGHT_HOLD_NO_CLIENT_ACCESS",
          "name": "Overnight hold (no client access)"
        },
        {
          "id": "RESET",
          "name": "Reset / turnaround"
        },
        {
          "id": "INTERVAL",
          "name": "Interval"
        },
        {
          "id": "CINEMA_SCREENING",
          "name": "Cinema screening"
        },
        {
          "id": "EXHIBITION",
          "name": "Exhibition"
        },
        {
          "id": "FILMING",
          "name": "Filming"
        },
        {
          "id": "PHOTOSHOOT",
          "name": "Photoshoot"
        },
        {
          "id": "PUBLIC",
          "name": "Open to the public"
        },
        {
          "id": "GALLERY EVENT",
          "name": "Gallery event"
        },
        {
          "id": "BLOCK",
          "name": "Tenancy"
        },
        {
          "id": "WEDDING_CEREMONY",
          "name": "Wedding ceremony"
        },
        {
          "id": "PIANO_TUNING",
          "name": "Piano tuning"
        },
        {
          "id": "PATCHING_SESSION",
          "name": "Patching session"
        },
        {
          "id": "CREW_BREAKS",
          "name": "Crew breaks"
        },
        {
          "id": "POST_PERFORMANCE_EVENT",
          "name": "Post-performance event"
        },
        {
          "id": "PRE_PERFORMANCE_EVENT",        
          "name": "Pre-performance event"
        },
        {
          "id": "RECEPTION",
          "name": "Reception in progress"
        },
        {
          "id": "SOUNDCHECK",
          "name": "Soundcheck"
        },
        {
          "id": "STAGE_TIME",
          "name": "Stage / technical time"
        },
        {
          "id": "PIANO_MAINTENANCE",
          "name": "Piano maintenance"
        },
        {
          "id": "WORKSHOP",
          "name": "Workshop"
        },
        {
          "id": "LOAD_IN_OUT",
          "name": "Load-in / load-out"
        },
        {
          "id": "PARKING",
          "name": "Parking"
        },
        {
          "id": "MEETING",
          "name": "Meeting in progress"
        },
        {
          "id": "ROOM_IN_USE",
          "name": "Room in use"
        },
        {
          "id": "CINEMA_MATINEE",
          "name": "Cinema matinee screening"
        },    
        {
          "id": "AUDIENCE_ARRIVE",
          "name": "Audience arrive"
        },
        {
          "id": "REFRESHMENTS_SERVICE",
          "name": "Refreshments service"
        },
      ].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      })
    }
  }  
};
</script>
