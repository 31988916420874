<template>
  <vue-typeahead-bootstrap
    placeholder="Search collections"
    v-model="searchString"
    :inputName="name"
    :data="suggestions"
    :serializer="item => serializer(item)"
    :disabledValues="(selected ? [selected.id] : [])"
    @hit="$emit('input', $event)"
    @input="lookup"
  />
</template>


<script>
import Mixins from "../../../mixins";
import gql from "graphql-tag";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  name: "SelectCollectionName",
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    value: {
      type: Object,
      default: null,
    },    
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
  },
  mixins: [Mixins],
  methods: {
    lookup() {
      if (this.searchString.length < 3) {
        this.suggestions = [];
        return false;
      } else {
        // use global timeout to debounce the search, i.e. don't do anything unless the user pauses for a while
        if (this.timeout) window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
          this.$apollo.query({
            query: gql`
              query { 
                collectionSearch ( searchString: ${JSON.stringify(this.searchString)} ) { 
                  name,
                  id,
                  events {
                    start {
                      formatted, year, month, day
                    }
                  }
                } 
              }
            `,
          })
          .then((result) => {
            this.suggestions = result.data.collectionSearch;
          })        
        }, 300);          
      }
    },
    serializer(item) {
      const reducer = (a, c) => {
        if (!a) {
          return c;
        } else if (!c) {
          return a;
        } else {
          return c.start.formatted < a.start.formatted ? c : a;
        }
      }

      let first = item.events.reduce(reducer);
      let date = `${first.start.year}-${first.start.month}-${first.start.day}`

      return `${item.name} ${date}`;
    }
  },      
  data() {
    return {
      suggestions: [],
      selected: null,
      searchString: null,
      timeout: null  
    };
  },
};
</script>
