<template>
  <b-container class="mt-3">   
    <div v-if="$apollo.queries.loading">Loading...</div> 
    <b-calendar block v-model="selectedDate" :date-info-fn="dateClass" locale="en" :disabled="$apollo.queries.events.loading" @context="onCalendarContext"></b-calendar>   
  </b-container>
</template>


<script>
  import datetime from 'date-and-time';
  import { EventStartFiltered } from '../../../queries/event.graphql';

  export default {
    name: 'CalendarPicker',

    created() {
      this.activeDate = new Date();
    },

    data() {
      return {
        activeDate: new Date()
      }
    },

    computed: {
      selectedDate: {
        get() {
          return this.$store.state.filters.selectedDate;
        },
        set(d) {
          this.$store.commit('selectedDate', d);
        }   
      },

      dateRange() {
        // start of month at midnight
        let start = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth(), 1, 0, 0, 0) 

        // if the start of the month isn't Sunday, we should look back to previous Sunday because the calendar shows full weeks 
        start.setDate(1 - start.getDay()); // no change if day 1 is Sunday, 0 sets back 1 day, 1 sets back 2 days etc

        let end = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() + 1, 0, 23, 59, 59);
        end.setDate(end.getDate() + (6 - end.getDay())); // no change if day 1 is Sunday, 0 sets back 1 day, 1 sets back 2 days etc

        return {
          start: start,
          end: end
        }
      },

      // array formatted for vue bootstrap calendar lookup
      activeDates() {
        if (this.events) {
          let days = this.events.map(event => {
            let d = new Date(event.start);            
            return datetime.format(d, "YYYY-MM-DD");
          })
          
          return [...new Set(days)];
        } else {
          return [];
        }
      }
    },
    
    methods: {
      dateClass(ymd, date) {        
        return this.activeDates.includes(ymd) ? 'table-info' : '';
      },
      onCalendarContext(context) {
        this.activeDate = context.activeDate
      }
    },

    // Apollo-specific options
    apollo: {
      $skipAll() {
        return !(this.$store.state.user && this.$store.state.user.expiresOn > Date.now())
      },    
      events: {
        query: EventStartFiltered,
        variables() {  
          return {
              ...this.$store.getters.filters,
              ...this.dateRange
          }
        },
      },
    },
  }  
</script>

<style lang="scss">
  .browse {
    margin-bottom: 2rem;
  }

  .b-calendar {
    width: 100%;
    max-width: 100%;
  }
</style>