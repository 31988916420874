<template>
  <div class="view-new-search">
    <dummy-tabs :tabs="tabs" />
    <div class="tab-pane">
      <b-card class="new-search">
        <div class="search-wrap">
          <search-form variant="dark" />
        </div>
        <b-card
          class="new-search"
          v-for="(result, index) in results"
          :key="index"
        >
          <b-row>
            <b-col sm="3">
              <label>Title</label>
            </b-col>
            <b-col sm="9">
              <div :id="result.name">{{ result.name }}</div>
            </b-col>
          </b-row>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import DummyTabs from "../../../components/Tabs";
import SearchForm from "../../../components/Search";
import gql from "graphql-tag";

export default {
  name: "NewSearch",
  components: {
    DummyTabs,
    SearchForm,
  },
  methods: {
    search(text) {
      console.log(text)
      this.$apollo.query({
        query: gql`
          query { 
            collectionSearch ( searchString: ${JSON.stringify(text)} ) { 
              name,
              id,
              events {
                start {
                  formatted, year, month, day
                }
              }
            } 
          }
        `,
      })
      .then((result) => {
        return result.data.collectionSearch;
      })         
    }
  },
  computed: {
    results() {
      console.log(this.$store.state.searchQuery);
      // return this.search(this.$store.state.searchQuery);
    }
  },
  data() {
    return {
      tabs: [
        {
          active: true,
          title: "New",
        },
      ],
    };
  },
};
</script>
